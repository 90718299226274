<template>
  <TitleText :background="'lightgreen'">
    <template slot="title">Hvem har mest på hjerte?</template>
    <template slot="subtitle">
      <p>Det er ikke nødvendigvis partiets ordfører, som taler mest om emnet i folketingssalen.</p> 
      <p>Her får du et overblik over, hvem der bruger mest tid på at tale om <span class="inverse">{{topicName.toLowerCase()}}</span> i folketingssalen fra hvert parti. Klik på personen, og se mere om, hvem de er. Kan du finde ministeren? </p>
    </template>
  </TitleText>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
span.inverse {
  background: #000;
  color: $color-lightgreen;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import TitleText from "@/components/TitleText";
export default {
  props: ["topicName"],
  components: {
    TitleText
  },
  computed: {
    getTopicName: function() {
      if (this.topicName === "EU") {
        return this.topicName;
      } else {
        return topicName.toLowerCase();
      }
    }
  }
};
</script>
