<template>
  <div class="info-container">
    <div class="close-btn" @click="closeLaw()"><img src="@/assets/icons/cancel.svg" alt="close"></div>
      <div class="info-text">
        <transition name="fade">
          <h1 v-show="!expandDescriptionBox" class="law-title">{{law.lawName.toUpperCase()}}</h1>
        </transition>
        <div class="law-description-container" :class="expandDescriptionBox?'expand':''">
          <h3 class="law-description-title">LOVTEKST</h3>
          <p class="law-description-text">{{law.summary.slice(0,250)}}<transition name="fade" mode="out-in"><span key="1" v-if="!shortenDescription">{{law.summary.slice(250,)}}</span><span key="2" v-else>...</span></transition>
          </p>
          <div class="read-more" @click="expandDescriptionBox = !expandDescriptionBox">
            <p v-if="!expandDescriptionBox">LÆS MERE</p>
            <p v-else>SKJUL</p>
          </div>
        </div>
        <transition name="fade">
          <p v-show="!expandDescriptionBox" class="minutes-text">SAMLET TALETID: {{(law.minutes).toFixed(0)}} MIN</p>
        </transition>
      </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.info-container {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .close-btn {
    position: absolute;
    top: 3%;
    left: 3%;
    width: 4%;
    height: 4%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .info-text {
    width: 70%;
    margin-left: 10%;
    height: 100%;
    text-align: left;
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    .law-image {
      width: 25%;
      position: relative;
      img {
        width: 100%;
      }
      .law-icon {
        position: absolute;
        width: 25%;
        top: -12%;
        right: -12%;
      }
    }
    h1.law-title {
      position: absolute;
      bottom: 55%;
      font-family: "futura-pt-condensed";
      font-weight: 800;
      font-size: 170%;
      margin: 0;
    }
    .law-description-container {
      position: absolute;
      top: 50%;
      height: 30%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      background: white;
      font-size: 100%;
      transition: 0.5s;
      z-index: 99;
      border: solid 5px $color-lightgrey;
      padding: 10% 2%;
      box-sizing: border-box;
      &.expand {
        height: 70%;
        top: 15%;
        box-shadow: 0px 0px 12px grey;
      }
      .law-description-title {
        position: absolute;
        top: 5%;
        font-family: "futura-pt";
        font-weight: 700;
        margin: 0;
      }
      .law-description-text {
        margin-right: 2%;
        text-align: justify;
        height: 90%;
        overflow-y: auto;
        transition: 0.5s;
      }
      .read-more {
        position: absolute;
        bottom: 5%;
        background-color: $color-lightgreen;
        font-family: "futura-pt";
        font-weight: 700;
        font-size: 120%;
        border-radius: 2px !important;
        border: none;
        padding: 1px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        p {
          margin: 0;
        }
      }
    }
    p.minutes-text {
      position: absolute;
      top: 85%;
      font-family: "futura-pt";
      font-weight: 700;
      margin: 0;
    }
  }
}
</style>

<script>
import parties from "@/assets/parties/parties.json";
export default {
  name: "lawLeft",
  props: ["law"],
  data() {
    return {
      parties,
      expandDescriptionBox: false,
      shortenDescription: true
    };
  },
  methods: {
    closeLaw: function() {
      this.$emit("closeLaw");
    },
    passedIcon: function(passed) {
      if (passed) {
        return require("@/assets/icons/success.svg");
      } else {
        return require("@/assets/icons/error.svg");
      }
    }
  },
  watch: {
    expandDescriptionBox: function() {
      let self = this;
      if (this.expandDescriptionBox === true) {
        setTimeout(function() {
          self.shortenDescription = false;
        }, 250);
      } else {
        this.shortenDescription = true;
      }
    }
  }
};
setTimeout;
</script>
