<template>
  <div class="wrapper">
    <div class="container" v-if="data">
      <div class="bar-chart-holder">
        <HorizontalBarChart :data="data" :topicName="topicName" :selectedPeriod="selectedPeriod"></HorizontalBarChart>
      </div>
      <h4>{{topicName}} er nummer {{topicPlacement[0]}} ud af {{topicPlacement[1]}} målt på antal {{dataName[selectedPage]}}.</h4>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.wrapper {
  background: $color-lightblue;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 100%;
  .bar-chart-holder {
    position: relative;
    width: 90%;
    height: 60%;
  }
  .title {
    text-transform: uppercase;
  }
  h4 {
    font-size: 120%;
    text-align: center;
    font-family: "futura-pt-condensed";
  }
  h4:first-letter {
    text-transform: capitalize;
  }
}
</style>

<script>
import axios from "axios";
import HorizontalBarChart from "@/components/HorizontalBarChart";

export default {
  props: ["topicName", "sectionData", "selectedPage"],
  components: {
    HorizontalBarChart
  },
  data() {
    return {
      data: null,
      generalStats: [],
      selectedPeriod: "allPeriods",
      // This might not be the smartest way, but need to make sure there is data there when we load:
      parsedData: [
        [
          {
            period: "period1",
            count: this.sectionData.govPeriods.period1.stats.nLaws,
            avg: 20
          },
          {
            period: "period2",
            count: this.sectionData.govPeriods.period2.stats.nLaws,
            avg: 33
          },
          {
            period: "period3",
            count: this.sectionData.govPeriods.period3.stats.nLaws,
            avg: 15
          },
          {
            period: "period4",
            count: this.sectionData.govPeriods.period4.stats.nLaws,
            avg: 25
          }
        ],
        [
          {
            period: "period1",
            count: this.sectionData.govPeriods.period1.stats.nBeslutnigsforslag,
            avg: 10
          },
          {
            period: "period2",
            count: this.sectionData.govPeriods.period2.stats.nBeslutnigsforslag,
            avg: 3
          },
          {
            period: "period3",
            count: this.sectionData.govPeriods.period3.stats.nBeslutnigsforslag,
            avg: 5
          },
          {
            period: "period4",
            count: this.sectionData.govPeriods.period4.stats.nBeslutnigsforslag,
            avg: 12
          }
        ]
      ],
      dataName: [
        "lov- og beslutningsforslag",
        "lovforslag",
        "beslutningsforslag"
      ]
    };
  },
  methods: {
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    }
  },
  // watch: {
  // },
  computed: {
    routeParameter: function() {
      return this.$route.params.topicID;
    },
    stats() {
      return this.parsedData[this.selectedPage - 1];
    },
    topicPlacement() {
      if (this.data) {
        let renderData = JSON.parse(JSON.stringify(this.data));
        renderData = renderData[this.selectedPeriod].sort(function(a, b) {
          return b.nLaws - a.nLaws;
        });

        let pos =
          renderData
            .map(function(e) {
              return e.topicName;
            })
            .indexOf(this.topicName) + 1;
        return [pos, renderData.length];
      } else {
        return null;
      }
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
    let self = this;
    axios.get("/static/overview.json").then(function(response) {
      self.generalStats[0] = response.data.SectionOverviewStatsAll.govPeriods;
      self.generalStats[1] = response.data.SectionOverviewStatsLaws.govPeriods;
      self.generalStats[2] =
        response.data.SectionOverviewStatsBeslutning.govPeriods;
      self.data = self.generalStats[self.selectedPage];
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  },
  watch: {
    selectedPage: function() {
      this.data = this.generalStats[this.selectedPage];
    },
    routeParameter: function() {
      let self = this;
      axios.get("/static/overview.json").then(function(response) {
        self.generalStats[0] = response.data.SectionOverviewStatsAll.govPeriods;
        self.generalStats[1] =
          response.data.SectionOverviewStatsLaws.govPeriods;
        self.generalStats[2] =
          response.data.SectionOverviewStatsBeslutning.govPeriods;
        self.data = self.generalStats[self.selectedPage];
      });
    }
  }
};
</script>
