<template>
  <Section>
    <HalfSection>
      <SectionLeft :topicName="topicName" />
    </HalfSection>
    <HalfSection>
      <SectionRight :sectionData="sectionData" />
    </HalfSection>
  </Section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
</style>

<script>
import Section from "@/components/Section";
import HalfSection from "@/components/HalfSection";
import SectionLeft from "./SectionLeft";
import SectionRight from "./SectionRight";
export default {
  props: ["topicName", "sectionData"],
  components: {
    Section,
    HalfSection,
    SectionLeft,
    SectionRight
  }
};
</script>
