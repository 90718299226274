<template>
  <TitleText :background="'orange'" :whiteText="true">
    <template slot="title">Vindende alliancer</template>
    <template slot="subtitle">
      Hvem hjælper regeringen med at få sin politik igennem? Se, hvilke alliancer der har haft størst indflydelse på <span class="inverse">{{topicName.toLowerCase()}}</span>. Selvom der ofte bliver indgået brede forlig, så kan der være nogle ret overraskende alliancer på området – og der kan også være store forskelle på, hvilke regeringer der laver flest brede forlig.  
    </template>
  </TitleText>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
span.inverse {
  background: #fff;
  color: $color-orange;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import TitleText from "@/components/TitleText";
export default {
  props: ["topicName"],
  components: {
    TitleText
  },
  computed: {
    getTopicName: function() {
      if (this.topicName === "EU") {
        return this.topicName;
      } else {
        return topicName.toLowerCase();
      }
    }
  }
};
</script>
