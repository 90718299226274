<template>
  <div class="vote-container" :class="law.passed?'passed':''">
    <div class="title-container">
      <h1 class="title" v-if="law.passed">Forslaget blev vedtaget</h1>
      <h1 class="title" v-else>Forslaget faldt</h1>
    </div>
    <div class="bars-container">
      <div class="mid-line"></div>
      <div class="pro-container" :style="`height: ${law.votes.for / (law.votes.for + law.votes.imod)*100}%`">
        <template v-for="(party, index) in law.partyInfo.filter(x => x.vote === 0).filter(x => checkForAlt(x))">
          <div class="vote-bar" :key="index" :style="partyBarStyle(party.partyID, true, index)">
            <p class="party-name">{{parties[party.partyID].name.toUpperCase()}}</p>
            <div v-if="index === 0" class="mandates-text">
              <p>{{law.votes.for}} MANDATER</p>
            </div>
          </div>
        </template>
        <div class="icon">
          FOR
        </div>
      </div>
      <div class="contra-container" :style="`height: ${law.votes.imod / (law.votes.for + law.votes.imod)*100}%`">
        <template v-for="(party, index) in law.partyInfo.filter(x => x.vote === 1).filter(x => checkForAlt(x))">
          <div class="vote-bar" :key="index" :style="partyBarStyle(party.partyID, false, index)">
            <p class="party-name">{{parties[party.partyID].name.toUpperCase()}}</p>
            <div v-if="index === 0" class="mandates-text">
              <p>{{law.votes.imod}} MANDATER</p>
            </div>
          </div>
        </template>
        <div class="icon">
          IMOD
        </div>
        <div class="abstentions-text" v-if="abstentionMandates > 0">
          <p>{{abstentionMandates}} HVERKEN FOR ELLER IMOD</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.vote-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 0, 0, 0.2);
}
.passed {
  background: $color-lightgreen;
}
.title-container {
  position: absolute;
  top: 5%;
}
.bars-container {
  position: relative;
  width: 70%;
  height: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 4px solid black;
  border-left: 4px solid black;
  border-right: 4px solid black;
}
.mid-line {
  position: absolute;
  height: 2px;
  border-bottom: 2px dashed black;
  box-sizing: border-box;
  width: 106%;
  top: 50%;
  transform: translateY(-50%);
  left: -3%;
  z-index: 5;
}
.pro-container,
.contra-container {
  position: relative;
  width: 45%;
  // border-bottom: 2px dashed $color-darkgrey;
  // border-left: 2px dashed $color-darkgrey;
  // border-right: 2px dashed $color-darkgrey;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 130%);
    width: 30%;
    font-size: 150%;
    color: rgba(0, 0, 0, 0.4);
    font-family: "futura-pt-condensed";
    font-weight: 800;
  }
}
.vote-bar {
  position: relative;
  box-sizing: border-box;
  color: white;
  .party-name {
    margin: 0;
    padding-left: 3%;
    text-align: left;
    font-size: 80%;
    font-family: "futura-pt-condensed";
    font-weight: 800;
  }
  .mandates-text {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    p {
      margin: 0;
      color: black;
      font-family: "futura-pt-condensed";
      font-weight: 800;
      font-size: 120%;
    }
  }
}
.abstentions-text {
  position: absolute;
  bottom: 0;
  right: -5%;
  transform-origin: top left;
  transform: translate(100%, 100%) rotate(-90deg);
  p {
    margin: 0;
    color: black;
    font-family: "futura-pt-condensed";
    font-weight: 800;
    font-size: 120%;
  }
}
</style>

<script>
import axios from "axios";
import parties from "@/assets/parties/parties.json";
export default {
  name: "lawRight",
  props: ["law", "selectedPeriod"],
  data() {
    return {
      parties,
      periods: null
    };
  },
  methods: {
    passedIcon: function(passed) {
      if (passed) {
        return require("@/assets/icons/success.svg");
      } else {
        return require("@/assets/icons/error.svg");
      }
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_med.png`);
    },
    checkForAlt: function(party) {
      if (party.partyID !== "ALT") {
        return true;
      } else {
        if (this.law.period === "period1" || this.law.period === "period2") {
          return false;
        } else {
          return true;
        }
      }
    },
    partyBarStyle: function(party, pro, index) {
      let self = this;
      // get total mandates of same vote
      if (this.periods !== null) {
        let lawPeriodData = this.periods.filter(
          x => x.periodID === this.law.period
        )[0];

        let partyMandates = lawPeriodData.partyMandates[party];

        let totalMandates = lawPeriodData.totalMandates;

        let proMandates = self.law.partyInfo
          .filter(x => x.vote === 0)
          .filter(x => this.checkForAlt(x))
          .map(x => {
            x.mandates = lawPeriodData.partyMandates[x.partyID];
            return x;
          })
          .reduce((prev, curr) => prev + curr.mandates, 0);

        let contraMandates = self.law.partyInfo
          .filter(x => x.vote === 1)
          .filter(x => this.checkForAlt(x))
          .map(x => {
            x.mandates = lawPeriodData.partyMandates[x.partyID];
            return x;
          })
          .reduce((prev, curr) => prev + curr.mandates, 0);

        let percentage = pro
          ? (partyMandates / proMandates) * 100
          : (partyMandates / contraMandates) * 100;

        let height = `height: ${percentage}%;`;

        let baseColor = 235;
        let mixColor = baseColor - index * 25;

        return pro
          ? height + `background: rgb(0, ${mixColor}, 0)`
          : height + `background: rgb(${mixColor}, 0, 0)`;
      } else {
        return null;
      }
    }
  },
  computed: {
    containerSize: function() {
      return {
        pro: `height: ${(this.law.votes.for /
          (this.law.votes.for + this.law.votes.imod)) *
          100}%`,
        vontra: `height: ${(this.law.votes.imod /
          (this.law.votes.for + this.law.votes.imod)) *
          100}%`
      };
    },
    abstentionMandates: function() {
      if (this.periods !== null) {
        let lawPeriodData = this.periods.filter(
          x => x.periodID === this.law.period
        )[0];

        return this.law.partyInfo
          .filter(x => x.vote === 2)
          .map(x => {
            x.mandates = lawPeriodData.partyMandates[x.partyID];
            return x;
          })
          .reduce((prev, curr) => prev + curr.mandates, 0);
      } else {
        return null;
      }
    },
    taletid: function() {
      return {
        pro: this.law.partyInfo
          .filter(x => !x.vote)
          .reduce((prev, curr) => prev + curr.minutes, 0)
          .toFixed(1),
        contra: this.law.partyInfo
          .filter(x => x.vote)
          .reduce((prev, curr) => prev + curr.minutes, 0)
          .toFixed(1)
      };
    }
  },
  mounted() {
    // this.periods doesn't always seem to be loaded before using the site.
    let self = this;
    axios
      .get("/static/periods.json")
      .then(response => (this.periods = response.data));
  }
};
</script>
