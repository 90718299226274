<template>
  <div class="outer-container">
    <MultiPageBox :pages="3" @selectedPage="setSelectedSlide" :animate="true">
      <template slot="page-1">
        <div class="inner-square">
          <div class="text-container">
            <div class="slide" v-bind:class="[{ active: slides[0] }, {hidden: !slides[0]}]">
              <h1 class="title">Hvor meget fylder området?</h1>
              <p class="subtitle">I {{periodsToText}} er der blevet fremlagt {{sectionData.govPeriods[selectedPeriod].stats.nLaws + sectionData.govPeriods[selectedPeriod].stats.nBeslutnigsforslag}} lov- og beslutningsforslag om <span class="black"><b>{{topicName.toLowerCase()}}</b></span>.</p>
              <p class="subtitle">Der er stor forskel på, hvor mange lovforslag en regering fremsætter på politiske områder. Det siger noget om, hvor vigtigt et område er for en regering. I folketingssalen stemmes der om to forskellige slags forslag: lovforslag og beslutningsforslag. </p>
              <p class="subtitle"><b>Lovforslag</b> kommer stort set altid fra regeringen og skal behandles tre gange i folketingssalen. <b>Beslutningsforslag</b> er en slags minilovforslag, som ofte stilles af mindre partier og oppositionen. Beslutningsforslag er dermed en måde, oppositionen kan prøve at få deres politik igennem på, men det er også en måde til at gøre opmærksom på politiske mærkesager.</p>
            </div>
          </div>
        </div>
      </template>
      <template slot="page-2">
        <div class="inner-square">
          <div class="text-container">
            <div class="slide" v-bind:class="[{ active: slides[1] }, {hidden: !slides[1]}]">
              <h1 class="title">Hvor meget fylder området for <span class="highlight">Regeringen</span>?</h1>
              <p class="subtitle">
                I {{periodsToText}} er der blevet fremlagt {{sectionData.govPeriods[selectedPeriod].stats.nLaws}} lovforslag om <span class="black"><b>{{topicName.toLowerCase()}}</b></span>.
              </p>
            </div>
          </div>
        </div>
      </template>
      <template slot="page-3">
        <div class="inner-square">
          <div class="text-container">
            <div class="slide" v-bind:class="[{ active: slides[2] }, {hidden: !slides[2]}]">
              <h1 class="title">Hvor meget fylder området for <span class="highlight">oppositionen</span>?</h1>
              <p class="subtitle">
                I {{periodsToText}} er der blevet fremlagt {{sectionData.govPeriods[selectedPeriod].stats.nBeslutnigsforslag}} beslutningsforslag om <span class="black"><b>{{topicName.toLowerCase()}}</b></span>.
              </p>
            </div>
          </div>
        </div>
      </template>

    </MultiPageBox>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.outer-container {
  background: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inner-square {
  position: relative;
  background: $color-orange;
  width: 67%;
  height: 90%;
  z-index: 10;
}
@media (max-width: $bp-narrow) {
  .inner-square {
    width: 90%;
  }
}
.text-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: 900;
    font-size: 300%;
    color: white;
    .highlight {
      // color: $color-yellow2;
      color: #000;
    }
  }
  .subtitle {
    font-size: 100%;
    color: #fff;
    text-align: left;
  }
  h4 {
    color: #fff;
  }
  span.inverse {
    background: #fff;
    color: #000;
    padding: 1px 3px 1px 3px;
    font-weight: bold;
  }
  span.black {
    color: #000;
  }
}
</style>

<script>
import MultiPageBox from "@/components/MultiPageBox";
// import TitleText from "@/components/TitleText";
export default {
  props: ["topicName", "sectionData", "selectedPage", "statsAll"],
  components: {
    MultiPageBox
  },
  data() {
    return {
      pages: 3,
      selectedSlide: 0,
      slides: [true, false, false],
      selectedPeriod: "allPeriods",
      periodNames: {
        period1: "perioden 2011 - 2014",
        period2: "perioden 2014 - 2015",
        period3: "perioden 2015 - 2016",
        period4: "perioden 2016 - 2019",
        allPeriods: "alle de valgte perioder"
      }
    };
  },
  methods: {
    setSelectedSlide: function(event) {
      this.selectedSlide = event - 1;
    },
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    },
    previous: function() {
      if (this.selectedSlide > 0) {
        this.selectedSlide--;
      } else {
        this.selectedSlide = 0;
      }
    },
    next: function() {
      if (this.selectedSlide < this.pages - 1) {
        this.selectedSlide++;
      } else {
        this.selectedSlide = this.pages - 1;
      }
    }
  },
  computed: {
    periodsToText: function() {
      return this.periodNames[this.selectedPeriod];
    },
    getTopicName: function() {
      if (this.topicName === "EU") {
        return this.topicName;
      } else {
        return topicName.toLowerCase();
      }
    }
  },
  watch: {
    selectedSlide: function() {
      let self = this;
      this.slides.forEach(function(i, index) {
        self.slides[index] = false;
      });
      this.slides[this.selectedSlide] = true;
      this.$emit("selectedPage", this.selectedSlide);
    }
    // page1: function() {
    //   this.$emit("selectedPage", this.page1);
    // }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
    this.$emit("selectedPage", this.selectedSlide);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>
