<template>
  <div class="outer-container">
    <div class="content-container" v-if="topicData">

      <!-- intro -->
      <SectionTopicIntro id="section-topic-intro" :topicName="topicName" @periodSelectorPosition="periodSelectorPosition = $event" view="topic" :topicsList="topicsList" />
      <SectionSpeakers id="section-speakers" :topicName="topicName" :sectionData="topicData.SectionSpeakers" />
      <SectionStatsGov id="section-stats-gov" :topicName="topicName" :sectionData="topicData.SectionStats" />

      <!-- voting -->
      <SectionIntroVoting id="section-intro-voting" :topicName="topicName" view="topic" />
      <SectionVotesParty id="section-votes-party" :topicName="topicName" :sectionData="topicData.SectionVotesParty" />
      <SectionVotesGrid id="section-votes-grid" :topicName="topicName" :sectionData="topicData.SectionVotesGrid" />
      <SectionAlliances id="section-alliances" :topicName="topicName" :sectionData="topicData.SectionAlliances" :averageData="overviewData.SectionOverviewAlliances" />
      <SectionLaws id="section-laws" :topicName="topicName" :sectionData="topicData.SectionLaws" />

      <!-- speeches -->
      <SectionIntroSpeeches id="section-intro-speeches" :topicName="topicName" view="topic" />
      <SectionPartyWords id="section-party-words" :topicName="topicName" :sectionData="topicData.SectionPartyWords" />
      <SectionWordBrowser id="section-word-browser" :topicName="topicName" view="topic" :searchwords="overviewData.SectionOverviewTop5Words.govPeriods" />

    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
</style>


<script>
// @ is an alias to /src
import axios from "axios";
import settings from "@/assets/styles/settings.scss";

import SectionTopicIntro from "@/sections/SectionTopicIntro/SectionTopicIntro.vue";
import SectionSpeakers from "@/sections/SectionSpeakers/SectionSpeakers.vue";
import SectionStatsGov from "@/sections/SectionStatsGov/SectionStatsGov.vue";

import SectionIntroVoting from "@/sections/SectionIntroVoting/SectionIntroVoting.vue";
import SectionVotesParty from "@/sections/SectionVotesParty/SectionVotesParty.vue";
import SectionVotesGrid from "@/sections/SectionVotesGrid/SectionVotesGrid.vue";
import SectionAlliances from "@/sections/SectionAlliances/SectionAlliances.vue";
import SectionLaws from "@/sections/SectionLaws/SectionLaws.vue";

import SectionIntroSpeeches from "@/sections/SectionIntroSpeeches/SectionIntroSpeeches.vue";
import SectionPartyWords from "@/sections/SectionPartyWords/SectionPartyWords.vue";
import SectionWordBrowser from "@/sections/SectionWordBrowser/SectionWordBrowser.vue";

export default {
  name: "topic",
  components: {
    SectionTopicIntro,
    SectionSpeakers,
    SectionStatsGov,
    SectionIntroVoting,
    SectionVotesParty,
    SectionVotesGrid,
    SectionAlliances,
    SectionLaws,
    SectionIntroSpeeches,
    SectionPartyWords,
    SectionWordBrowser
  },
  data() {
    return {
      topicID: this.$route.params.topicID,
      topicData: null,
      topicName: null,
      overviewData: null,
      settings
    };
  },
  methods: {
    setTopicData: function(data) {
      this.topicData = data.topicData;
      this.topicName = data.topicName;
    },
    setOverviewData: function(data) {
      this.overviewData = data;
    }
  },
  computed: {
    topicsList: function() {
      return this.overviewData.SectionOverviewStatsAll.govPeriods.allPeriods;
    }
  },
  beforeRouteEnter(to, from, next) {
    axios.get(`/static/topic${to.params.topicID}.json`).then(response => {
      let topicData = response.data;
      axios.get(`/static/overview.json`).then(response => {
        let overviewData = response.data;
        next(vm => {
          vm.setTopicData(topicData);
          vm.setOverviewData(overviewData);
        });
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
    axios.get(`/static/topic${to.params.topicID}.json`).then(response => {
      this.setTopicData(response.data);
      next();
    });
  }
};
</script>
