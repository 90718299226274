import { render, staticRenderFns } from "./SectionRight.vue?vue&type=template&id=bbe66670&scoped=true"
import script from "./SectionRight.vue?vue&type=script&lang=js"
export * from "./SectionRight.vue?vue&type=script&lang=js"
import style0 from "./SectionRight.vue?vue&type=style&index=0&id=bbe66670&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbe66670",
  null
  
)

export default component.exports