<template>
  <TitleText :background="'red'" :whiteText="true">
    <template slot="title">HVEM SAGDE HVAD</template>
    <template slot="subtitle">
      Der kan være stor forskel på, hvad partierne lægger vægt på i forhold til <span class="inverse">{{topicName.toLowerCase()}}</span>. Klik på et parti, og se, hvilke tre ord de oftest benytter i debatten om emnet.</p>
      <p>Hvis du klikker på cirklen, så kan du se forskellen på seks politikere fra det parti, du har valgt.</p>
    </template>
  </TitleText>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.left-container {
  background: $color-red;
  color: white;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.inner-container {
  width: 60%;
  text-align: left;
  position: relative;
}
.quotes {
  font-size: 10rem;
  font-family: "Times New Roman", Times, serif;
  position: absolute;
  top: -90px;
  left: -80px;
}
span.inverse {
  background: #fff;
  color: $color-red;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import TitleText from "@/components/TitleText";
export default {
  props: ["topicName"],
  components: {
    TitleText
  },
  computed: {
    getTopicName: function() {
      if (this.topicName === "EU") {
        return this.topicName;
      } else {
        return this.topicName.toLowerCase();
      }
    }
  }
};
</script>
