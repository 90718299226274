<template>
  <div class="outer-container">
    <div class="inner-container">
      <template v-for="(law, index) in lawsPageLaws">
        <div class="law-tile" :class="`law-tile-${index+1}`" :key="index" @click="selectLaw(law)">
          <div class="law-image">
            <img class="passed-icon" :src="passedIcon(law.passed)" alt="">
            <!-- <div class="law-icon">
              <img class="passed-icon" :src="passedIcon(law.passed)" alt="">
            </div> -->
          </div>
          <h4>{{law.lawNameDisplay.toUpperCase()}}</h4>
          <p class="minutes-text">SAMLET TALETID: {{Math.floor(law.minutes)}} MIN</p>
        </div>
      </template>

      <div class="law-tile law-tile-empty" v-if="lawsPageLaws.length<2"></div>
      <div class="law-tile law-tile-empty" v-if="lawsPageLaws.length<3"></div>
      <div class="law-tile law-tile-empty" v-if="lawsPageLaws.length<4"></div>
      <div class="law-tile law-tile-empty" v-if="lawsPageLaws.length<5"></div>
      <div class="law-tile law-tile-empty" v-if="lawsPageLaws.length<6"></div>

      <div class="arrow-container">
        <div class="arrow-box" @click="currentLawsPage>0?currentLawsPage--:none" :class="arrowBoxClass.previous">
          <img :src="prev" height="60%" alt="back">
        </div>
          <div class="page-box">
            <p>{{currentLawsPage+1}}/{{lawsPagesTotal}}</p>
          </div>
          <div class="arrow-box" @click="currentLawsPage<lawsPagesTotal-1?currentLawsPage++:none" :class="arrowBoxClass.next">
            <img :src="next" height="60%" alt="next">
        </div>
          </div>

        </div>
      </div>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

a {
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: none;
    color: black;
  }
}
.outer-container {
  height: 100%;
  width: 100%;
}
.inner-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.law-tile {
  width: 50%;
  height: calc(90% / 3);
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  h4 {
    font-family: "futura-pt-condensed";
    font-weight: 800;
    font-size: 120%;
    margin: 0;
  }
  p.minutes-text {
    font-family: "futura-pt";
    font-weight: 700;
    font-size: 70%;
  }
  // border: 1px solid grey;
}
.law-tile-1 {
  background: #f2f2f2;
}
.law-tile-2 {
  background: #f8ce54;
}
.law-tile-3 {
  background: #64d1e2;
}
.law-tile-4 {
  background: #eb8a54;
}
.law-tile-5 {
  background: #d85b53;
}
.law-tile-6 {
  background: #b3b3b3;
}
.law-tile-empty {
  background: rgba(0, 0, 0, 0.1);
}
.law-image {
  width: 15%;
  margin-top: 5%;
  position: relative;
  img {
    width: 100%;
  }
}
.law-icon {
  position: absolute;
  height: 20%;
  width: 20%;
  top: -10%;
  right: -10%;
}

.arrow-container {
  display: flex;
  width: 100%;
  height: 6%;
  margin: 1.5% 0;
  justify-content: space-between;
  // background: red;
}
.arrow-box,
.page-box {
  background: $color-grey;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}
.arrow-box {
  width: 40%;
  cursor: pointer;
}
.page-box {
  width: 16%;
  p {
    font-family: "futura-pt";
    font-weight: 400;
    font-size: 100%;
  }
}
.arrow-disabled {
  opacity: 0.2;
  cursor: default;
}
</style>

<script>
export default {
  props: ["sectionData", "selectedPeriod"],
  data() {
    return {
      currentLawsPage: 0
    };
  },
  methods: {
    passedIcon: function(passed) {
      if (passed) {
        return require("@/assets/icons/law-yes.svg");
      } else {
        return require("@/assets/icons/law-no.svg");
      }
    },
    selectLaw: function(law) {
      this.$emit("selectedLaw", law);
      this.smoothScrollTo("#section-laws");
    },
    smoothScrollTo: function(target) {
      let header = 100;
      let scrollY = document.querySelector(target).offsetTop - header;
      window.scroll({ top: scrollY, behavior: "smooth" });
    }
  },
  computed: {
    laws: function() {
      if (this.sectionData && this.selectedPeriod) {
        return this.sectionData.govPeriods[this.selectedPeriod].laws;
      } else {
        return [];
      }
    },
    lawsPagesTotal: function() {
      return Math.ceil(this.laws.length / 6);
    },
    lawsPageLaws: function() {
      let start = 0 + this.currentLawsPage * 6;
      let end = 6 + this.currentLawsPage * 6;
      return this.laws.slice(start, end);
    },
    arrowBoxClass: function() {
      return {
        previous: this.currentLawsPage !== 0 ? "" : "arrow-disabled",
        next:
          this.currentLawsPage !== this.lawsPagesTotal - 1
            ? ""
            : "arrow-disabled"
      };
    },
    prev: function() {
      return require("@/assets/icons/back-black.svg");
    },
    next: function() {
      return require("@/assets/icons/next-black.svg");
    }
  }
};
</script>
