<template>
  <transition name="fade" mode="out-in">
    <Section v-if="overview" key="1">
      <HalfSection>
        <SectionLeft :topicName="topicName" />
      </HalfSection>
      <HalfSection>
        <SectionRight :sectionData="sectionData" :selectedPeriod="selectedPeriod" @selectedLaw="selectLaw($event)" />
      </HalfSection>
    </Section>
    <Section v-else key="2">
      <HalfSection>
        <LawLeft :law="selectedLaw" @closeLaw="overview = true" />
      </HalfSection>
      <HalfSection>
        <LawRight :law="selectedLaw" :selectedPeriod="selectedPeriod" @closeLaw="overview = true"/>
      </HalfSection>
    </Section>
  </transition>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
</style>

<script>
import Section from "@/components/Section";
import HalfSection from "@/components/HalfSection";
import SectionLeft from "./SectionLeft";
import SectionRight from "./SectionRight";
import LawLeft from "./LawLeft";
import LawRight from "./LawRight";
export default {
  components: {
    Section,
    HalfSection,
    SectionLeft,
    SectionRight,
    LawLeft,
    LawRight
  },
  props: ["topicName", "sectionData"],
  data() {
    return {
      selectedPeriod: "period1",
      overview: true,
      selectedLaw: null
    };
  },
  methods: {
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    },

    selectLaw: function(law) {
      this.selectedLaw = law;
      this.overview = false;
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>
