import { render, staticRenderFns } from "./SectionVotesGrid.vue?vue&type=template&id=9d8a3ce6&scoped=true"
import script from "./SectionVotesGrid.vue?vue&type=script&lang=js"
export * from "./SectionVotesGrid.vue?vue&type=script&lang=js"
import style0 from "./SectionVotesGrid.vue?vue&type=style&index=0&id=9d8a3ce6&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d8a3ce6",
  null
  
)

export default component.exports