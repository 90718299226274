<template>
  <div class="outer-container">
    <transition name="fade" mode="out-in">
      <div v-if="circleView" class="circle-container" key="1">
        <div class='outer-circle-container'>
          <template v-for="(info, party) in parties">
            <li :key="party">
              <!-- <img v-if="periodParties.indexOf(party)!==-1" :src="partyLogo(party)" alt="..." @click="selectedParty = party" :class="selectedParty === party?'clicked':''" />
              <img v-else :src="partyLogo(party)" alt="..." class="disabled-party" /> -->
              <div class="party-logo" v-if="periodParties.indexOf(party)!==-1" @click="selectedParty = party" :class="selectedParty === party?'clicked':''">
                <PartyLogo :party="party" :size="'med'" />
              </div>
              <div class="party-logo disabled-party" v-else>
                <PartyLogo :party="party" :size="'med'" />
              </div>
              <!-- <PartyLogo :party="party" :size="'med'" v-else class="disabled-party" /> -->

            </li>
          </template>
        </div>
        ´ <div class="inner-circle" @click="circleView = false">
          <div class="edge-circle">
            <div class="edge" :style="rotateEdge">
              <img src="@/assets/icons/edge.svg" alt="">
            </div>
          </div>
          <div class="circle-text">
            <h2>{{parties[selectedParty].name.toUpperCase()}}:</h2>
            <p>1. {{partyWords[selectedParty].top3[0].toUpperCase()}}</p>
            <p>2. {{partyWords[selectedParty].top3[1].toUpperCase()}}</p>
            <p>3. {{partyWords[selectedParty].top3[2].toUpperCase()}}</p>
            <div class="center-text">
              <!-- <p class="read-more">Tryk for at se politikernes mest brugte ord</p> -->
              <button>SE MERE</button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="speaker-container" key="2">
        <div class="close-btn" @click="circleView = true">
          <img src="@/assets/icons/cancel.svg" alt="">
        </div>
        <template v-for="(speaker, index) in partyWords[selectedParty].politicians">
          <div class="speaker-tile" :class="`speaker-tile-${index}`" :key="index">
            <div class="speaker-image">
              <!-- <img src="@/assets/politicians/speaker.png" alt="" height="100%"> -->
              <img @click="selectPolitician(speaker.politicianID, speaker.politician, speaker.partyID)" :src="getStaticPoliticianImg(speaker)" alt="" height="100%">
              <div class="speaker-party-logo">
                <img :src="partyLogo(selectedParty)" alt="" width="100%">
              </div>
            </div>
            <h4 class="speaker-title" @click="selectPolitician(speaker.politicianID, speaker.politician, speaker.partyID)">{{speaker.politician}}</h4>
            <ul class="speaker-words word-break">
              <li v-for="(word, index) in speaker.words" :key="index" class="word-break">{{index + 1}}. {{word[0].toUpperCase() + word.slice(1,word.length)}}</li>
            </ul>
          </div>
        </template>
      </div>
    </transition>

    <PoliticianInfo :speaker="selectedSpeaker" :showPoliticianInfo="showPoliticianInfo" @closePolitician="showPoliticianInfo = false" />

  </div>
</template>

<style lang="scss" scoped>
// Circle code adapted from https://css-tricks.com/snippets/sass/placing-items-circle/
@import "@/assets/styles/settings.scss";
@import "./circle.scss";

.circle-container {
  position: relative;
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outer-circle-container {
  @include on-circle($item-count: 9, $circle-size: 550%, $item-size: 7em);
  margin: 0;
  .clicked {
    width: 70%;
    height: 70%;
  }
  .disabled-party {
    cursor: initial;
    opacity: 0.3;
  }
}
.outer-container {
  background: white;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  padding-top: 65%;
  // background: rgba(0, 0, 0, 0.3);
  background: $color-darkgrey;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 black;
  &::before {
    content: "";
    // z-index: -1;
    position: absolute;
    transition: 0.1s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $color-darkgrey;
  }
  &:hover {
    &::before {
      top: -0.5%;
      left: -0.5%;
      width: 101%;
      height: 101%;
    }
  }
}
.edge-circle {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  // border: 3px dashed red;
  border-radius: 50%;
  box-sizing: border-box;
  .edge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 19%;
    height: 19%;
    margin: -(19% / 2);
    // border: 1px dashed blue;
    img {
      transform: rotate(90deg);
      width: 100%;
      height: 100%;
    }
  }
}
.circle-text {
  width: 70%;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  h2 {
    font-family: "futura-pt";
    font-weight: 700;
    font-size: 180%;
  }
  .center-text {
    margin-top: 30%;
    position: relative;
    width: 75%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    button {
      background: $color-red;
      color: white;
      font-family: "futura-pt";
      font-weight: 700;
      cursor: pointer;
      border: none;
      font-size: 140%;
    }
  }
  p.read-more {
    font-size: 70%;
    text-transform: uppercase;
    font-weight: 500;
  }
  p {
    font-size: 110%;
    font-weight: 700;
  }
}
.party-logo {
  position: absolute;
  cursor: pointer;
  width: 55%;
}
.speaker-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.close-btn {
  position: absolute;
  top: 3%;
  left: 3%;
  width: 4%;
  height: 4%;
  z-index: 99;
  img {
    height: 100%;
    width: 100%;
  }
}
.speaker-tile {
  width: 50%;
  height: calc(100% / 3);
  padding: 2%;
  box-sizing: border-box;
}
.speaker-tile-0,
.speaker-tile-3,
.speaker-tile-4 {
  background: darken($color-lightgrey, 5%);
}
.speaker-image {
  height: 50%;
  position: relative;
  img {
    cursor: pointer;
  }
}
.speaker-party-logo {
  position: absolute;
  top: 20%;
  right: 15%;
  width: 13%;
}
h4.speaker-title {
  font-family: "futura-pt";
  font-weight: 700;
  text-transform: uppercase;
  height: 15%;
  margin: 0;
  cursor: pointer;
}

ul.speaker-words {
  padding-top: 0;
  margin-top: 0;
  height: 30%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  text-align: left;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  li {
    font-size: 80%;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    flex: 0 0 30%; /* explanation below */
  }
}

.word-break {
  /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>

<script>
import globalConfig from "@/assets/globalConfig.json";
import parties from "@/assets/parties/parties.json";
import PoliticianInfo from "@/components/PoliticianInfo";
import PartyLogo from "@/components/PartyLogo";

export default {
  props: ["sectionData"],
  components: {
    globalConfig,
    PoliticianInfo,
    PartyLogo
  },
  data() {
    return {
      selectedPeriod: "period1",
      parties,
      visibleCard: null,
      selectedSpeaker: null,
      speakers: [
        "speaker1",
        "speaker2",
        "speaker3",
        "speaker4",
        "speaker5",
        "speaker6"
      ],
      circleView: true,
      selectedParty: "S",
      showPoliticianInfo: false
    };
  },
  methods: {
    selectPolitician: function(id, name, party) {
      // console.log("Clicked: ", id, name);
      this.selectedSpeaker = {
        partyID: party,
        politician: name,
        politicianID: id,
        politicianPhoto: ""
      };
      this.showPoliticianInfo = true;
    },
    showInfoCard: function(index) {
      this.visibleCard = index;
    },
    hideInfoCard: function() {
      this.visibleCard = null;
    },
    infoCardClass: function(index) {
      if (this.visibleCard === index) {
        return "show-card";
      } else {
        return "";
      }
    },
    hideSpeakerClass: function(index) {
      if (this.visibleCard && this.visibleCard !== index) {
        return "hide-speaker";
      } else {
        return "";
      }
    },
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_med.png`);
    },
    getStaticPoliticianImg: function(speaker) {
      let url =
        globalConfig.staticAssetsCDN +
        "/images/politicianID_" +
        speaker.politicianID +
        "_size_240.jpg";
      return url;
    }
  },
  computed: {
    selectedPartyIndex: function() {
      return Object.keys(this.parties).indexOf(this.selectedParty);
    },
    rotateEdge: function() {
      return `transform: rotate(${0 +
        ((this.selectedPartyIndex * 360) / 9) * 1}deg) translate(${570 / 2}%)
    rotate(${0 + this.selectedPartyIndex * -1}deg)`;
    },
    partyWords: function() {
      if (this.sectionData && this.selectedPeriod) {
        return this.sectionData.govPeriods[this.selectedPeriod];
      } else {
        return null;
      }
    },
    periodParties: function() {
      return Object.keys(this.sectionData.govPeriods[this.selectedPeriod]);
    }
  },
  watch: {
    selectedPeriod: function() {
      if (
        this.selectedPeriod === "period1" ||
        this.selectedPeriod === "period2"
      ) {
        if (this.selectedParty === "ALT") {
          this.circleView = true;
          this.selectedParty = "S";
        }
      }
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>
