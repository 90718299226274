<template>
  <TitleText :background="'turquoise'" :whiteText="true">
    <template slot="title">Vennerne</template>
    <template slot="subtitle">

      <p>Hvem stemmer et parti ofte sammen med inden for <span class="inverse">{{topicName.toLowerCase()}}</span>? 
      <p>I Danmark er der en tradition for at lave brede aftaler, og det betyder, at partierne relativt ofte stemmer det samme. Men der er stadig store forskelle på, hvilke partier der minder meget om hinanden – og det kan skifte meget fra emne til emne. Husk også at gå på opdagelse i de forskellige regeringsperioder, for stemmemønstre kan ændre sig meget over tid. </p>

    </template>
  </TitleText>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
span.inverse {
  background: #fff;
  color: $color-turquoise;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import TitleText from "@/components/TitleText";
export default {
  props: ["info", "topicName"],
  components: {
    TitleText
  },
  computed: {
    getTopicName: function() {
      if (this.topicName === "EU") {
        return this.topicName;
      } else {
        return topicName.toLowerCase();
      }
    }
  }
};
</script>
