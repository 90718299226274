<template>
  <TitleText :background="'yellow'">
    <template slot="title">Er blokkene i opløsning?</template>
    <template slot="subtitle">
      
      <p>Der er meget snak om rød og blå blok. Se, hvilke partier der oftest stemmer det samme inden for <span class="inverse">{{topicName.toLowerCase()}}</span>.</p> 
      <p>Desto tættere partierne er på hinanden, desto oftere stemmer de det samme på tværs af alle afstemninger i folketingssalen.</p> 
      <p>Gå også på opdagelse i, hvordan det ændrer sig mellem regeringsperioderne. Kan man stadig tale om, at der er tydelig forskel på rød og blå blok i dansk politik?</p>

    </template>
  </TitleText>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
span.inverse {
  background: #000;
  color: $color-yellow;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import TitleText from "@/components/TitleText";
export default {
  props: ["info", "topicName"],
  components: {
    TitleText
  },
  computed: {
    getTopicName: function() {
      if (this.topicName === "EU") {
        return this.topicName;
      } else {
        return topicName.toLowerCase();
      }
    }
  }
};
</script>
