<template>
  <TitleText :background="'white'">
    <template slot="title">Mest kontroversielle lov- og beslutningsforslag</template>
    <template slot="subtitle">
      <p>Der bliver fremsat omkring 200 lovforslag og 100 beslutningsforslag i Folketinget om året. En del af dem er der bred enighed om. Men der er også forslag, hvor partierne er meget uenige, og hvor der bruges særligt lang tid på at debattere dem i folketingssalen. Se de forslag, som er blevet debatteret i længst tid i folketingssalen indenfor <span class="inverse">{{topicName.toLowerCase()}}</span>. </p>
      <p>Klik på forslaget, og læs mere om, hvad det handler om, og hvem der har stemt hvad. Alle forslagene er færdigbehandlet, men ikke nødvendigvis stemt igennem.</p>
    </template>
  </TitleText>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
span.inverse {
  background: #000;
  color: #fff;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import TitleText from "@/components/TitleText";
export default {
  props: ["topicName"],
  components: {
    TitleText
  },
  computed: {
    getTopicName: function() {
      if (this.topicName === "EU") {
        return this.topicName;
      } else {
        return topicName.toLowerCase();
      }
    }
  }
};
</script>
